import { UserAccountType } from '../enums/user-account-type.enum';
import { CompanyResponse } from './company.model';
import { LocationResponse } from './location.model';

export class UserResponse {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  pinCode?: string;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;
  lastActive?: string;
  lastEmailDateChange?: string;
  cognitoId?: string;
  locationId?: number;
  location?: LocationResponse;
  company?: CompanyResponse;

  constructor(model: UserResponse) {
    this.id = model.id || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.email = model.email || '';
    this.pinCode = model.pinCode || '';
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
    this.lastActive = model.lastActive || '';
    this.lastEmailDateChange = model.lastEmailDateChange || '';
    this.cognitoId = model.cognitoId || '';
    this.locationId = model.locationId || 0;
    this.location = model.location || (null as any);
    this.company = model.company || (null as CompanyResponse);
  }
}

export class UserResponseView {
  id?: number;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  companyName?: string;
  email?: string;
  location?: string;
  isSuperUser?: boolean;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;

  constructor(model: UserResponseView) {
    this.id = model.id || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.fullName = `${model.firstName} ${model.lastName}`;
    this.companyName = model.companyName || '';
    this.email = model.email || '';
    this.location = model.location || '';
    this.isSuperUser = model.isSuperUser || false;
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
  }
}

export class CreateUserRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  pinCode?: string;
  companyId?: number;
  locationId?: number;
  userTypeId?: number;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;
  userAccountType?: UserAccountType;

  constructor(model: CreateUserRequest) {
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.email = model.email?.length ? model.email.trim().toLowerCase() : '';
    this.pinCode = model.pinCode || '';
    this.companyId = model.companyId || 0;
    this.locationId = model.locationId || 0;
    this.userTypeId = model.userTypeId || null;
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
    this.userAccountType =
      model.userAccountType || UserAccountType.CompeteAdmin;
  }
}

export class UpdateUserRequest {
  id?: number;
  firstName?: string;
  lastName?: string;
  pinCode?: string;
  companyId?: number;
  locationId?: number;
  isSuperUser?: boolean;
  avatar?: string;
  logo?: string;
  baymanagerAdmin?: boolean;
  competeAdmin?: boolean;
  companyAdmin?: boolean;

  constructor(model: UpdateUserRequest) {
    this.id = model.id || 0;
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.pinCode = model.pinCode || '';
    this.companyId = model.companyId || 0;
    this.locationId = model.locationId || 0;
    this.isSuperUser = model.isSuperUser || false;
    this.avatar = model.avatar || '';
    this.logo = model.logo || '';
    this.baymanagerAdmin = model.baymanagerAdmin;
    this.competeAdmin = model.competeAdmin;
    this.companyAdmin = model.companyAdmin;
  }
}

export class CreateUserResponse {
  id?: number;
  cognitoId?: string;
  error?: string;

  constructor(model: CreateUserResponse) {
    this.id = model.id || 0;
    this.cognitoId = model.cognitoId || '';
    this.error = model.error || '';
  }
}

export class DeleteUserRequest {
  id?: number;

  constructor(model: DeleteUserRequest) {
    this.id = model.id || 0;
  }
}

export class ChangePasswordObject {
  code?: string;
  username?: string;

  constructor(model: ChangePasswordObject) {
    this.code = model.code || '';
    this.username = model.username || '';
  }
}
