import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Injector,
  OnDestroy,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FsPopupOptions, FS_POPUP_TOKEN } from '../fs-popups-shared';
import {
  AnchorElementPosition,
  DOMRectFull,
  Point,
  TransferElementMethod,
} from '../../fs-controls-shared.model';
import { FsAnimationService } from '../../fs-animations/fs-animation.service';
import { AnimationOptions } from '../../fs-animations/fs-animation.model';
import { sleep } from 'src/app/shared/helpers/helper-functions';

@Component({
  selector: 'fs-popup-overlay',
  templateUrl: './fs-popup-overlay.component.html',
  styleUrls: ['./fs-popup-overlay.component.scss'],
})
export class FsPopupOverlayComponent implements AfterViewInit, OnDestroy {
  @ViewChild('popupContainer', { static: true, read: ViewContainerRef })
  popupContainer!: ViewContainerRef;

  @Output('close') close = new EventEmitter<any>();

  isBusy: boolean;
  showOverlay: boolean;
  popupStyle: Object;
  dialogFrame: HTMLElement;
  popupComp: ComponentRef<any>;
  lastTransferPoint: number;
  anchorRect: DOMRectFull;

  private _popup: Type<any>;
  public get popup(): Type<any> {
    return this._popup;
  }
  public set popup(v: Type<any>) {
    this._popup = v;
  }

  private _options: FsPopupOptions;
  public get options(): FsPopupOptions {
    return this._options;
  }
  public set options(v: FsPopupOptions) {
    this._options = v;
  }

  constructor(
    private injector: Injector,
    private animation: FsAnimationService
  ) {
    this.popupStyle = {
      width: 'unset',
      height: 'unset',
      left: 'unset',
      top: 'unset',
      'z-index': 'unset',
      transform: 'unset',
    };

    this.lastTransferPoint = 0;
    this.showOverlay = false;
    this.isBusy = true;
  }

  ngOnDestroy(): void {
    (<EventEmitter<any>>this.popupComp.instance.close)?.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(async () => {
      await sleep(10);
      this.showOverlay = true;
    });
  }

  public initialOverlay(): void {
    this.isBusy = true;

    if (this._options.anchorToElement) {
      this.anchorRect = new DOMRectFull(this._options.anchorToElement);

      const size = this.calcPopupSize();
      const distance = this.calcPopupDistance();

      this.popupStyle['width'] = `${size.x}px`;
      this.popupStyle['height'] = `${size.y}px`;
      this.popupStyle['left'] = `${distance.x}px`;
      this.popupStyle['top'] = `${distance.y}px`;
    }

    this.createComponent();
  }

  private async createComponent(): Promise<void> {
    this.popupComp = this.popupContainer.createComponent(this._popup, {
      injector: Injector.create({
        providers: [
          { provide: FS_POPUP_TOKEN, useValue: this.options.content },
        ],
        parent: this.injector,
      }),
    });

    (<EventEmitter<any>>this.popupComp.instance.close)?.subscribe((result) =>
      this.onClose(result)
    );

    this.dialogFrame = (<HTMLElement>(
      this.popupComp.location.nativeElement
    )).querySelector('.dialog-frame');

    this.lastTransferPoint = this.calcStartPoint();

    switch (this._options.transferMethod) {
      case TransferElementMethod.DownToUp:
        this.dialogFrame.style.transform = `translateY(${this.lastTransferPoint}px)`;
        break;
      case TransferElementMethod.UpToDown:
        this.dialogFrame.style.transform = `translateY(${this.lastTransferPoint}px)`;
        break;
      case TransferElementMethod.LeftToRight:
        this.dialogFrame.style.transform = `translateX(${this.lastTransferPoint}px)`;
        break;
      case TransferElementMethod.RightToLeft:
        this.dialogFrame.style.transform = `translateX(${this.lastTransferPoint}px)`;
        break;
      default:
        break;
    }

    if (this._options.anchorToElement) {
      this.dialogFrame.classList.add('wrap-size');
    } else {
      if (!this._options.autoSize) {
        this.dialogFrame.style.width = `${this._options.width}px`;
        this.dialogFrame.style.height = `${this._options.height}px`;
      } else {
        // this.dialogFrame.style.maxWidth = `${this._options.width}px`;
        // this.dialogFrame.style.maxHeight = `${this._options.height}px`;
      }
    }

    await this.transformFrame(true);

    this.isBusy = false;
  }

  calcStartPoint(): number {
    if (this._options.anchorToElement) {
      switch (this._options.transferMethod) {
        case TransferElementMethod.DownToUp:
          return this._options.height;
        case TransferElementMethod.UpToDown:
          return this._options.height * -1;
        case TransferElementMethod.LeftToRight:
          return this._options.width * -1;
        case TransferElementMethod.RightToLeft:
          return this._options.width;
        default:
          return 0;
      }
    } else {
      switch (this._options.transferMethod) {
        case TransferElementMethod.DownToUp:
          return window.innerHeight / 2 + this._options.height / 2;
        case TransferElementMethod.UpToDown:
          return (window.innerHeight / 2 + this._options.height / 2) * -1;
        case TransferElementMethod.LeftToRight:
          return (window.innerWidth / 2 + this._options.width / 2) * -1;
        case TransferElementMethod.RightToLeft:
          return window.innerWidth / 2 + this._options.width / 2;
        default:
          return 0;
      }
    }
  }

  /**
   * محاسبه اندازه پنجره پاپ آپ نسبت به صفحه نمایش
   * این محاسبه بر اساس اندازه های داده شده در option هست .
   * ولی گاهن ممکنه اندازه پنجره با توجه به موقعیت قرار گیریش ، از صفحه نمایش بیرون بزنه
   * برای همین باید به نسبت خاصی از اندازه داده شده در option کم بشه
   *
   * @return Point
   */
  calcPopupSize(): Point {
    const sWidth = window.innerWidth,
      sHeight = window.innerHeight;

    const point = new Point({
      x: this._options.width,
      y: this._options.height,
    });

    if (
      this.anchorRect.centerELementBottom > this.anchorRect.centerELementTop &&
      this.anchorRect.centerELementRight > this.anchorRect.centerELementLeft
    ) {
      const fromTopScreen = this.anchorRect.top + point.y,
        fromLeftScreen = this.anchorRect.left + point.x;

      if (fromTopScreen > sHeight) {
        const heightDiff = Math.round(fromTopScreen - sHeight);
        point.y -= heightDiff;
      }

      if (fromLeftScreen > sWidth) {
        const widthDiff = Math.round(fromLeftScreen - sWidth);
        point.x -= widthDiff;
      }
    } else if (
      this.anchorRect.centerELementBottom < this.anchorRect.centerELementTop &&
      this.anchorRect.centerELementRight > this.anchorRect.centerELementLeft
    ) {
      const fromBottomScreen = this.anchorRect.bottomScreen + point.y,
        fromLeftScreen = this.anchorRect.left + point.x;

      if (fromBottomScreen > sHeight) {
        const heightDiff = Math.round(fromBottomScreen - sHeight);
        point.y -= heightDiff;
      }

      if (fromLeftScreen > sWidth) {
        const widthDiff = Math.round(fromLeftScreen - sWidth);
        point.x -= widthDiff;
      }
    } else if (
      this.anchorRect.centerELementBottom > this.anchorRect.centerELementTop &&
      this.anchorRect.centerELementRight < this.anchorRect.centerELementLeft
    ) {
      const fromTopScreen = this.anchorRect.top + point.y,
        fromRightScreen = this.anchorRect.rightScreen + point.x;

      if (fromTopScreen > sHeight) {
        const heightDiff = Math.round(fromTopScreen - sHeight);
        point.y -= heightDiff;
      }

      if (fromRightScreen > sWidth) {
        const widthDiff = Math.round(fromRightScreen - sWidth);
        point.x -= widthDiff;
      }
    } else if (
      this.anchorRect.centerELementBottom < this.anchorRect.centerELementTop &&
      this.anchorRect.centerELementRight < this.anchorRect.centerELementLeft
    ) {
      const fromBottomScreen = this.anchorRect.bottomScreen + point.y,
        fromRightScreen = this.anchorRect.rightScreen + point.x;

      if (fromBottomScreen > sHeight) {
        const heightDiff = Math.round(fromBottomScreen - sHeight);
        point.y -= heightDiff;
      }

      if (fromRightScreen > sWidth) {
        const widthDiff = Math.round(fromRightScreen - sWidth);
        point.x -= widthDiff;
      }
    }

    this._options.width = point.x;
    this._options.height = point.y;

    return point;
  }

  /**
   * محاسبه میکنه که پنجره پاپ آپ ، بر حسب المان نگه دارنده ، دقیقا کجا قرار بگیره
   *
   * @returns Point
   */
  calcAnchorPosition(): Point {
    switch (this._options.anchorElementPosition) {
      case AnchorElementPosition.StartStart:
        return new Point({
          x: 0,
          y: 0,
        });
      case AnchorElementPosition.StartCenter:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width / 2 - this.anchorRect.width / 2,
            y: 0,
          });
        else
          return new Point({
            x: this.anchorRect.width / 2 - this._options.width / 2,
            y: 0,
          });
      case AnchorElementPosition.StartEnd:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width - this.anchorRect.width,
            y: 0,
          });
        else
          return new Point({
            x: this.anchorRect.width - this._options.width,
            y: 0,
          });
      case AnchorElementPosition.CenterStart:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: 0,
            y: this._options.height / 2 - this.anchorRect.height / 2,
          });
        else
          return new Point({
            x: 0,
            y: this.anchorRect.height / 2 - this._options.height / 2,
          });
      case AnchorElementPosition.CenterCenter:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width / 2 - this.anchorRect.width / 2,
            y: this._options.height / 2 - this.anchorRect.height / 2,
          });
        else
          return new Point({
            x: this.anchorRect.width / 2 - this._options.width / 2,
            y: this.anchorRect.height / 2 - this._options.height / 2,
          });
      case AnchorElementPosition.CenterEnd:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width - this.anchorRect.width,
            y: this._options.height / 2 - this.anchorRect.height / 2,
          });
        else
          return new Point({
            x: this.anchorRect.width - this._options.width,
            y: this.anchorRect.height / 2 - this._options.height / 2,
          });
      case AnchorElementPosition.EndStart:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: 0,
            y: this._options.height - this.anchorRect.height,
          });
        else
          return new Point({
            x: 0,
            y: this.anchorRect.height - this._options.height,
          });
      case AnchorElementPosition.EndCenter:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width / 2 - this.anchorRect.height / 2,
            y: this._options.height - this.anchorRect.height,
          });
        else
          return new Point({
            x: this.anchorRect.width / 2 - this._options.height / 2,
            y: this.anchorRect.height - this._options.height,
          });
      case AnchorElementPosition.EndEnd:
        if (this.anchorRect.width <= this._options.width)
          return new Point({
            x: this._options.width - this.anchorRect.width,
            y: this._options.height - this.anchorRect.height,
          });
        else
          return new Point({
            x: this.anchorRect.width - this._options.width,
            y: this.anchorRect.height - this._options.height,
          });
    }
  }

  /**
   * محاسبه فاصله پنجره پاپ آپ نسبت به اطراف صفحه نمایش
   *
   * @returns Point
   */
  calcPopupDistance(): Point {
    const position = this.calcAnchorPosition();

    switch (this._options.transferMethod) {
      case TransferElementMethod.DownToUp:
        return new Point({
          x: this.anchorRect.left - position.x,
          y: this.anchorRect.bottom - position.y,
        });
      case TransferElementMethod.UpToDown:
        return new Point({
          x: this.anchorRect.left - position.x,
          y: this.anchorRect.top - position.y,
        });
      case TransferElementMethod.LeftToRight:
        return new Point({
          x: this.anchorRect.left - position.x,
          y: this.anchorRect.top - position.y,
        });
      case TransferElementMethod.RightToLeft:
        return new Point({
          x: this.anchorRect.right - position.x,
          y: this.anchorRect.top - position.y,
        });
      default:
        return new Point({
          x: 0,
          y: 0,
        });
    }
  }

  async transformFrame(reverse: boolean): Promise<void> {
    return this.animation.animate(
      new AnimationOptions({
        duration: this._options.anchorToElement ? 400 : 650,
        timing: this.animation.quad,
        draw: (value: number) => {
          const x = this.lastTransferPoint * value;

          switch (this._options.transferMethod) {
            case TransferElementMethod.DownToUp:
              this.dialogFrame.style.transform = `translateY(${x}px)`;
              break;
            case TransferElementMethod.UpToDown:
              this.dialogFrame.style.transform = `translateY(${x}px)`;
              break;
            case TransferElementMethod.LeftToRight:
              this.dialogFrame.style.transform = `translateX(${x}px)`;
              break;
            case TransferElementMethod.RightToLeft:
              this.dialogFrame.style.transform = `translateX(${x}px)`;
              break;
          }
        },
        reverse,
      })
    );
  }

  onClosePopup(event: MouseEvent): void {
    if (this.isBusy || !this._options.onClickBackdropToClose) return;

    const classes = (<HTMLElement>event.target).classList;

    if (
      classes.contains('fs-overlay') ||
      classes.contains('fs-modal-container')
    ) {
      this.onClose();
    }
  }

  async onClose(result: any = null): Promise<void> {
    this.isBusy = true;

    await this.transformFrame(false);

    this.showOverlay = false;
    await sleep(150);

    this.popupComp.destroy();
    this.close.emit(result);

    this.isBusy = false;
  }
}
