import { InjectionToken } from '@angular/core';
import {
  AnchorElementPosition,
  ElementSize,
  FSActionElement,
  InnerElementsLayoutDirection,
  TransferElementMethod,
} from '../fs-controls-shared.model';

export enum QuestionsDialogRole {
  Delete = 1,
}

export enum AlertModalType {
  Info = 1,
  Warning = 2,
  Danger = 3,
  Success = 4,
  Question = 5,
}

export class QDialogDeleteData {
  itemName?: string;
  role?: QuestionsDialogRole;
  showCloseButton?: boolean;

  constructor(model: QDialogDeleteData) {
    this.itemName = model.itemName || 'Item';
    this.role = model.role || QuestionsDialogRole.Delete;
    this.showCloseButton = model.showCloseButton || false;
  }
}

export class AlertModalOption<V> {
  width?: number;
  height?: number;
  type?: AlertModalType;
  title?: string;
  description?: any;
  showCloseButton?: boolean;
  actions?: FSActionElement<V>[];

  constructor(model: AlertModalOption<V>) {
    this.width = model.width || 250;
    this.height = model.height || 150;
    this.type = model.type || AlertModalType.Info;
    this.title = model.title || '';
    this.description = model.description || '';
    this.showCloseButton = model.showCloseButton || true;
    this.actions = model.actions || (null as any);
  }
}

export class FsPopupOptions<T = any> {
  anchorToElement?: HTMLElement;
  anchorElementPosition?: AnchorElementPosition;
  transferMethod?: TransferElementMethod;
  content?: PopupContent<T>;
  width?: number;
  height?: number;
  autoSize?: boolean;
  hasBackdropFilter?: boolean;
  onClickBackdropToClose?: boolean;

  constructor(model: FsPopupOptions<T>) {
    this.anchorToElement = model.anchorToElement || (null as any);
    this.anchorElementPosition =
      model.anchorElementPosition || AnchorElementPosition.StartStart;
    this.transferMethod = model.transferMethod || TransferElementMethod.Default;
    this.content = model.content || (null as any);
    this.width = model.width || 0;
    this.height = model.height || 0;

    if (model.autoSize === undefined || model.autoSize === null)
      this.autoSize = false;
    else {
      this.autoSize = model.autoSize;

      this.content = new PopupContent({
        ...model.content,
        autoSize: model.autoSize,
      });
    }

    this.hasBackdropFilter = model.hasBackdropFilter || false;
    this.onClickBackdropToClose = model.onClickBackdropToClose || false;
  }
}

export class PopupContent<T = null, V = any> {
  classes?: string;
  title?: string;
  body?: string;
  data?: T;
  hideCloseButton?: boolean;
  contentLayoutDir?: InnerElementsLayoutDirection;
  contentSize?: ElementSize;
  hideAlertIcon?: boolean;
  autoSize?: boolean;
  actions?: FSActionElement<V>[];

  constructor(model: PopupContent<T>) {
    this.classes = model.classes || '';
    this.title = model.title || '';
    this.body = model.body || '';
    this.data = model.data || (null as any);
    this.hideCloseButton = model.hideCloseButton || false;
    this.contentLayoutDir =
      model.contentLayoutDir || InnerElementsLayoutDirection.Block;
    this.contentSize = model.contentSize || 'md';
    this.hideAlertIcon = model.hideAlertIcon || false;
    this.autoSize = model.autoSize;
    this.actions = model.actions || (null as any);
  }
}

export const FS_POPUP_TOKEN = new InjectionToken<PopupContent<any>>(
  'fs_popup_token'
);

export enum PopupActionToDo {
  SubmitOrClose = 1,
  ChangeState = 2,
  ChangeStateAndFillData = 3,
}

export class PopupStateConfig<State = number, Data = any> {
  current?: State;
  next?: State;
  data?: Data;
  todo?: PopupActionToDo;

  constructor(model: PopupStateConfig<State, Data>) {
    this.current = model.current || (null as any);
    this.next = model.next || (null as any);
    this.data = model.data || (null as any);
    this.todo = model.todo || PopupActionToDo.SubmitOrClose;
  }
}
